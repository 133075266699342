<template>
  <el-dialog title="开通会员" :visible.sync="visible" class="mock-vip-tip">
    <p>
      {{ i18n.Mock.vipTips }}
    </p>
    <img src="@/assets/mockExam/vipTipImg.png" alt="" />
    <div class="btn">
      <el-button type="primary" @click="handleVip">{{
        i18n.Mock.ToOpen
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    handleVip() {
      this.$router.push({ name: 'vip' })
    },
  },
}
</script>

<style scoped lang="scss">
.mock-vip-tip {
  .btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    ::v-deep .el-button {
      background-color: #3b86ff;
      border-color: #3b86ff;
      min-width: 160px;
    }
  }
}
</style>
