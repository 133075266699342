<template>
  <div class="page-list">
    <div class="list">
      <ul class="header">
        <li class="name">{{ i18n.Mock.name }}</li>
        <li>{{ i18n.Mock.progress }}</li>
        <li>{{ i18n.Mock.Creation }}</li>
        <li class="tools">{{ i18n.Mock.operation }}</li>
      </ul>
      <ul v-for="item of list" :key="item.id">
        <li class="name">
          {{ item.exam_name }}
        </li>
        <li>
          <span class="score" v-if="item.score_status == 1 && item.exam_type == 1">{{
            $t('Mock.score')
          }}</span>
          <span class="finish" v-else-if="item.is_finish == 1">{{
            i18n.Mock.Completed
          }}</span>
          <span v-else>{{ item.task_rate }}%</span>
        </li>
        <li>{{ item.created_at }}</li>
        <li class="tools">
          <template v-if="item.is_finish == 1">
            <span class="btn" @click="handleDtail(item)">{{
              i18n.Mock.see
            }}</span>
          </template>
          <template v-else>
            <template>
              <span class="btn" @click="handleContinueTo(item)">{{
                i18n.Mock.continue
              }}</span>
              <span class="split">|</span>
            </template>
            <span class="btn" @click="handleDelete(item)">{{
              i18n.Mock.delete
            }}</span>
          </template>
        </li>
      </ul>
    </div>

    <Empty :msg="i18n.Mock.There" v-if="list.length === 0"></Empty>
    <div class="pagination" v-else>
      <el-pagination
        small
        layout="prev, pager, next"
        :current-page="pageIndex"
        :total="total"
        :page-size="5"
        @current-change="handlePage"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/exam'
import Empty from '@/components/Empty'
import emitter from '@/utils/emitter'

export default {
  components: {
    Empty
  },
  data() {
    return {
      pageIndex: 1,
      total: 0,
      list: []
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    }
  },
  created() {
    this.fetchList()

    this.refreshList = this.fetchList.bind(this)
    emitter.on('VISIBILITY', this.refreshList)
  },
  beforeDestroy() {
    emitter.off('VISIBILITY', this.refreshList)
  },
  methods: {
    async fetchList() {
      const {
        data: {
          list,
          pager: { total_cnt }
        }
      } = await api.meList({
        page: this.pageIndex,
        page_size: 5
      })

      this.list = list
      this.total = total_cnt
    },
    handlePage(page) {
      this.pageIndex = page
      this.fetchList()
    },

    handleDtail(item) {
      this.$router.push({
        name: 'examDetail',
        query: {
          id: item.exam_id,
          examId: item.id
        }
      })
    },
    handleContinueTo(item) {
      window.open(
        this.$router.resolve({
          name: 'examTest',
          query: {
            id: item.exam_id,
            examId: item.id,
            type: 'continue'
          }
        }).href,
        '_blank'
      )
    },
    async handleRetake(item) {
      this.$confirm('重考将会清空记录，确定重新开始吗？', '重考').then(
        async () => {
          const { data, code } = await api.resetExam({
            my_exam_id: item.id
          })

          if (code !== 200) {
            return
          }

          window.open(
            this.$router.resolve({
              name: 'examTest',
              query: {
                id: item.exam_id,
                examId: item.id
              }
            }).href,
            '_blank'
          )
        }
      )
    },
    handleDelete(item) {
      this.$confirm('是否要删除该练习？', '删除练习', {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Ok'
      }).then(async () => {
        const { msg } = await api.deleteExam({
          my_exam_id: item.id
        })
        this.$message.success(msg)

        this.handlePage(1)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page-list {
  margin-top: 12px;
  .list {
    border-radius: 10px;
    background-color: #4a4a58;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    overflow: hidden;
  }
  ul {
    display: flex;
    align-items: center;
    padding: 0 38px;
    position: relative;
    &.header {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
      &:after {
        display: none;
      }
      > li {
        padding: 30px 0px 20px 0px;
        font-size: 18px;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 40px;
      width: calc(100% - 80px);
      height: 1px;
      background-color: #ebebf2;
    }
    > li {
      flex: 1;
      padding: 18px 0;
      text-align: center;
      line-height: 1.2;
      color: #fff;
      font-size: 16px;
      &.name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
      &.tools {
        width: 100px;
        flex: none;
      }
      .split {
        margin: 0 8px;
      }
      .btn {
        cursor: pointer;
        &:hover {
          color: #ef575b;
        }
      }
      .score {
        color: #f2bf66;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: -15px;
          top: 3px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #f2bf66;
        }
      }
      .finish {
        color: #3ec2a3;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: -15px;
          top: 3px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #3ec2a3;
        }
      }
    }
  }

  .pagination {
    text-align: center;
    margin-top: 15px;
    ::v-deep .el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}
</style>
