<template>
  <div class="page-list">
    <div class="block">
      <div class="title">{{ i18n.Mock.title }}</div>
      <MeList />
    </div>
    <div class="block">
      <div class="title">{{ i18n.Mock.paper }}</div>
      <AllList />
    </div>
  </div>
</template>

<script>
import MeList from './me'
import AllList from './all'

export default {
  components: {
    MeList,
    AllList,
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
}
</script>

<style scoped lang="scss">
.page-list {
  .block {
    &:nth-child(n + 2) {
      margin-top: 36px;
    }
  }
  .title {
    color: #1f1f40;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
